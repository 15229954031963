<template>
	<div>
		<div class="accordion" role="tablist" style="height: 300px">
			<div v-for="category of categoryList" :key="category.key">
				<b-card no-body class="mb-1">
					<b-card-header header-tag="header" class="p-0" role="tab">
						<b-button class="no-border text-left" block v-b-toggle="`accordion-${category.key}`" variant="secondary">
							<span class="when-closed"><BIconCaretRight /></span>
							<span class="when-opened"><BIconCaretDown /></span>
							{{category.label}} ({{ refs.output[category.key].length }})
						</b-button>
					</b-card-header>
					<b-collapse class="ref-area" :id="`accordion-${category.key}`" accordion="my-accordion" role="tabpanel">
						<RefsOutputAccordionPagination
							class="mt-3 ml-3"
							v-model="category.currentPage"
							:total-rows="refs.output[category.key].length"
							:per-page="perPage"
							:disabled="category.isLoading"
						/>
						<RefsTableLens @loading="category.isLoading = $event" :categoryKey="category.key" :currentPage="category.currentPage" :perPage="perPage" />
					</b-collapse>
				</b-card>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';

import formatMixin from '../mixins/formatMixin'
import serverMixin from '../mixins/serverMixin'
import RefsOutputAccordionPagination from './RefsOutputAccordionPagination.vue';

import RefsTableLens from './RefsTableLens.vue'

export default {
	name: "RefsInputAccordion",
	mixins: [
		formatMixin,
		serverMixin
	],
	components: {
		RefsTableLens,
		RefsOutputAccordionPagination
	},
	computed: {
		...mapGetters({
			refs: 'references/getRefs'
		})
	},
	data() {
		return {
			perPage: 100,
			categoryList: [
				{
					key: "references",
					label: "Backwards Citation Search",
					currentPage: 1,
					isLoading: false
				},
				{
					key: "citations",
					label: "Forwards Citation Search",
					currentPage: 1,
					isLoading: false
				}
			]
		}
	},
	methods: {
		setLoading(i, isLoading) {
			this.categoryList[i].isLoading = isLoading;
		}
	}
}
</script>

<style>
.no-border:focus, .no-border:active {
	outline: none !important;
	box-shadow: none !important;
}
.ref-area {
	max-height: calc(100vh - 220px) !important;
	overflow: auto;
}
.collapsed > .when-opened,
:not(.collapsed) > .when-closed {
		display: none;
}
</style>